import React from "react";
import Layout from "../../components/Layout";

export default function tecnologiaEducativa() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_salud/5.jpg)`}}
                    >
                        <h1>Tecnología Educativa</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Plataformas tecnológicas enfocadas al desarrollo de habilidades comunicacionales:
                            comprensión lectora, inglés comunitario, escritura científicas y revisión de originalidad de
                            todo tipo de manuscrito.
                            Nuestro amplio catálogo de soluciones incluye plataformas para gestión documental integral,
                            consolidación de colecciones digitales, desarrollo de todo tipo de bibliotecas digitales,
                            así como laboratorios virtuales y simuladores STEM:
                        </p>
                        <ul style={{color: '#000000'}}>
                            <li>Simuladores y laboratorios virtuales</li>
                            <li>Desarrollo de bibliotecas virtuales</li>
                            <li>Tecnología para el aprendizaje del inglés</li>
                            <li>Plataforma para desarrollo de competencias lectoras</li>
                            <li>Plataforma de servicios bibliotecarios</li>
                            <li>Sistemas antiplagio</li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
}